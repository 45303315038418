(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
'use strict'

const angular = require('angular');
require('angular-animate');
require('angular-tablesort');
require('angular-ui-router');
require('./lib/templates.js')

// Create a module for the app
angular.module('aq2', [
	'ui.router',
	'ngAnimate',
	'tableSort',
	'templates',
])

.run(['$rootScope', function($rootScope) {
	// Mobile nav is disabled by default
	$rootScope.showMobileNav = false;

	// When state changes, hide mobile nav
	$rootScope.$on('$stateChangeStart', function(ev) {
		$rootScope.showMobileNav = false;
	});
}]);

require('./routes.js');
require('./filters.js');
require('./main/Main_controller');
require('./history/History_controller');
require('./servers/Servers_controller');
require('./info/Info_controller');

}).call(this,require("Wb8Gej"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_65122ebf.js","/")