(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
'use strict'

const angular = require('angular');

angular.module('aq2')

.controller('ServersController',
['$q', '$http', '$scope', '$window', '$interval',
function($q, $http, $scope, $window, $interval) {
	var self = this;

	/**
	 * Loads the server list from q2online
	 * @return void
	 */
	self.getServers = function() {
		$scope.error = null;

		// Load the servers
		$http.jsonp('ajax.php?game=action&matchmode=&_players=1', {jsonpCallbackParam: '_callback'})
		.then(function(resp) {
			// If request was successfull, save response data as servers
			$scope.servers = resp.data || [];
		})
		.catch(function(err) {
			// If there was an error, clear the servers and show the error
			$scope.servers = [];
			$scope.error = err;
		});
	}

	// Get the servers on page load
	self.getServers();

	// State flags
	self.shouldUpdate = false;
	self.focused = true;

	// Update the servers every 60s, when window is active
	var timer = $interval(function() {
		// If window is focused, just get the servers
		if (self.focused) {
			self.shouldUpdate = false;
			self.getServers();
		}
		else {
			// Otherwise set update-flag on
			self.shouldUpdate = true;
		}
	}, 60 * 1000);

	/**
	 * Listens to windows focus event and updates the server list if needed
	 * @return void
	 */
	$window.onfocus = function() {
		self.focused = true;

		if (self.shouldUpdate) {
			self.shouldUpdate = false;
			self.getServers();
		}
	};

	/**
	 * Listens to blur-event to set focused-flag
	 * @return void
	 */
	$window.onblur = function() {
		self.focused = false;
	};

	/**
	 * Opens the given url in a new window
	 * @param  String url		Server url
	 * @return void
	 */
	$scope.openServer = function(url) {
		$window.open(url);
	};
}]);

}).call(this,require("Wb8Gej"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/servers/Servers_controller.js","/servers")