(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
'use strict'

const angular = require('angular');

angular.module('aq2')
.config(['$stateProvider', '$urlRouterProvider',
function($stateProvider, $urlRouterProvider) {
	$urlRouterProvider.otherwise('/');

	$stateProvider
	.state('index', {
		url: '/',
		templateUrl: 'main/index.html'
	})
	.state('servers', {
		url: '/palvelimet',
		templateUrl: 'servers/servers.html',
	})
	.state('history', {
		url: '/historia',
		templateUrl: 'history/history.html',
	})
	.state('info', {
		url: '/info',
		templateUrl: 'info/info.html',
	});
}]);
}).call(this,require("Wb8Gej"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/routes.js","/")